import React from "react";
// CSS helpers
import styled from "styled-components";

// Images
import Image from "assets/images/demos/gismondi-darmo-societhy.jpeg";

// Images & Videos
import HomeVideo from "assets/video/darmosociethy.mp4";

const Container = styled.div`
    /* background-color: var(--blue); */
    position: absolute;
    top: 80px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    overflow: hidden;
`;

const Video = styled.video`
    width: 100%;
    /* height: 100%; */
`;

export const ExperienceContainer = () => {
    return (
        <Container>
            <Video autoPlay muted loop>
                <source src={HomeVideo} type="video/mp4" />
            </Video>
        </Container>
    );
};
